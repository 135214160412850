@import "../../assets/scss/variables";

.wr_table {
  .unit_preloader {
    position: absolute;
    height: 100%;
    z-index: 9;
    background-color: #fff;
    opacity: 0.8;
    pointer-events: all;
  }
}

.unit_preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  font-size: 10px;
  text-indent: -99999em;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: "";
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  transform-origin: 5.1em 5.1em;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  transform-origin: 0.1em 5.1em;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
