@import '../../assets/scss/variables';

.notification {
  position: fixed;
  right: 20px;
  top: 20px;
  display: flex;
  z-index: 99999;
  justify-content: space-between;
  align-items: center;
  max-width: 440px;
  height: 56px;
  padding-left: 16px;
  padding-right: 18px;
  color: #fff;
  box-shadow: $shadow;
  border-radius: 4px;

  &_success {
    background: #4ab866;

    .notification-icon {
      color: #4ab866;
    }
  }

  &_warning {
    background: #f0b849;

    .notification-icon {
      color: #f0b849;
    }
  }

  &_info {
    background: #5097ee;

    .notification-icon {
      color: #5097ee;

      i {
        font-size: 16px;

      }
    }
  }

  &_error {
    background: #d94f4f;

    .notification-icon {
      color: #d94f4f;
    }
  }
}

.notification-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  margin-right: 16px;
  i {
    font-size: 14px;
    font-weight: bold;
  }
}
.message-text-wr{
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.message-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.7;
  color: #FFFFFF;
}

.message-close {
  cursor: pointer;
  i {
    font-size: 20px;
  }
}