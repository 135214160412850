@import "../../assets/scss/variables";

.popup {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99;
  top: 0;
  left: 0;
  padding-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $popupBackGround;

  &-wrapper {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    max-width: 288px;
    width: 100%;
    height: auto;
    max-height: 90vh;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &_big {
      max-width: 384px;
    }

    &-buttons {
      span {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      i {
        cursor: pointer;
      }
    }

    &-header {
      width: 100%;
      justify-content: center;
      display: flex;

      h3 {
        font-weight: bold;
        font-size: 24px;
        line-height: 1.6;
        text-align: center;
        margin-bottom: 24px;
        color: $dark;
        margin-top: 0;
      }

      &_sticky {
        z-index: 2;
        padding: 20px;
        border-radius: 4px 4px 0px 0px;
        box-sizing: border-box;
        max-height: 94px;
        width: 100%;
        top: 0;
        background: #fff;
        position: absolute;
        justify-content: space-between;
        display: flex;
        align-items: center;

        h3 {
          font-weight: bold;
          font-size: 24px;
          line-height: 1.6;
          text-align: center;
          margin-bottom: 0;
          color: $dark;
          margin-top: 0;
        }
      }
    }

    &-header + &-sub-text {
      margin-top: 8px;
      text-align: center;
    }

    &-sub-text {
      font-size: 14px;
      line-height: 1.7;
      color: $dark;
    }

    .hintHello &_tooltips,
    .hintGlobalSettings &_tooltips,
    .hintNodes &_tooltips {
      &:after {
        display: none;
      }
    }

    &_tooltips {
      position: relative;
      overflow-y: unset;

      &:after {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &:after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: #fff;
        border-width: 16px;
        margin-left: -16px;
      }
    }
  }

  &-body {
    max-height: 500px;
    overflow-y: auto;
    padding: 86px 20px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    z-index: 1;

    .searched-test-plan {
      border-radius: 4px;
      display: flex;
      width: calc(100% - 32px);
      padding: 12px 16px;
      box-shadow: $shadow;
      margin-bottom: 16px;
      cursor: pointer;
      transition: 0.1s;

      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 1.7;
        color: $dark;
        padding: 0;
        margin: 0;
      }

      &_active {
        background: #e6f0fd;
      }

      &:hover {
        background: #e6f0fd;
      }
    }
  }

  &-bottom {
    width: 100%;

    &_sticky {
      border-radius: 0px 0px 4px 4px;
      width: 100%;
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      background: #fff;
      padding: 0 24px 24px 24px;
      z-index: 2;
    }
  }

  .info {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.7;
    color: $dark;
  }

  .btn-tooltip {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 14px;

    &__item {
      cursor: pointer;
      margin-left: 32px;
      font-weight: 600;
      font-size: 14px;
      line-height: 1;
      color: $dark40;

      &_active {
        color: #056ae7;
      }
    }
  }

  .button {
    margin-top: 24px;

    &:nth-child(2) {
      margin-top: 10px;
    }
  }

  .label-input {
    width: 100%;
  }

  .popup-row + .popup-row {
    margin-top: 32px;
  }

  .creds {
    margin-top: 0px !important;
  }

  .popup-roles {
    font-weight: 600;
    font-size: 14px;
  }

  .popup-row {
    display: flex;
    width: 100%;
    flex-flow: row;
    justify-content: flex-start;

    &_jsb {
      justify-content: space-between;
    }

    &_afs {
      align-items: flex-start;
    }

    .label-input {
      width: 48%;
    }

    &_readOnly {
      display: flex;
      flex-flow: column;
      margin-bottom: 16px;

      span {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        color: $dark70;
        max-height: 240px;
        overflow: auto;
      }

      .error-message {
        padding-right: 10px;
        word-break: break-all;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .custom-select {
    width: 100%;
    margin-bottom: 16px;
  }

  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin: 0;
    }
  }

  &-body {
    width: 100%;
  }
}

.updateAction .popup-wrapper-sub-text {
  min-height: 100px;
}

.hintTabs,
.hintTest,
.hintTestSettings,
.hintGroups,
.hintPlan,
.hintSettings {
  position: absolute;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 1366px) {
  .hintPlan {
    .popup-wrapper {
      max-width: 250px;
      left: 1px !important;
    }
  }
  .hintSettings {
    .popup-wrapper {
      max-width: 250px;
    }
  }
}
