@import "../../assets/scss/variables";

.sidenav {
  height: 100%;
  width: 92px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  transition: 0.5s;

  &_list_wrapper {
    width: 60px;
    padding-top: 25px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    background-color: #fff;
    transition: 0.5s;
    overflow-x: hidden;

    .logo-wrapper {
      margin-bottom: 73px;

      .logo {
        padding-left: 13px;
      }
    }
  }

  &_wrapper {
    display: flex;
    align-items: center;
    height: 50px;
    cursor: pointer;

    a,
    span {
      min-width: 150px;
      height: 100%;
      display: flex;
      align-items: center;
      width: 100%;
    }

    span,
    i {
      padding: 0 22px;
      text-decoration: none;
      font-size: 16px;
      color: $dark40;

      &:nth-child(2) {
        padding: 0;
      }
    }

    i {
      height: 100%;
      line-height: 50px;
    }

    .icon-tree,
    .icon-gears {
      transform: rotate(-90deg);
    }
  }

  &_bottom {
    width: 100%;
    margin-top: auto;

    div:nth-child(2) {
      margin-top: 0px;
    }

    a,
    span {
      font-size: 14px;
    }
  }

  .closebtn {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 16px;
    width: 32px;
    height: 64px;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 4px 0px 16px rgba(0, 114, 255, 0.06);
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;

    i {
      transform: rotate(180deg);
    }
  }

  .login-info {
    display: flex;
    align-items: center;
    padding: 13px 10px;

    .img-user {
      min-width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 24px;
    }

    .info {
      min-width: 178px;
      margin-left: 15px;
      display: flex;
      flex-flow: column;

      .name {
        font-weight: 600;
        line-height: 1.3;
        color: $dark;
        word-break: break-all;
      }

      .role {
        font-size: 12px;
        color: $dark70;
      }
    }
  }
}

.open {
  .sidenav_list_wrapper {
    width: 250px;
  }

  .closebtn {
    i {
      transform: rotate(0deg);
    }
  }
}
